<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="全部" name="0" >
        <lecturelist v-if="activeName == '0'" :state="null"></lecturelist>
      </el-tab-pane>
      <el-tab-pane label="未开始" name="1">
        <lecturelist v-if="activeName == '1'"  :state="0"></lecturelist>
      </el-tab-pane>
      <el-tab-pane label="已结束" name="2">
        <lecturelist v-if="activeName == '2'"  :state="-1"></lecturelist>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import lecturelist from "./components/lecturelist";
export default {
  components:{
    lecturelist,
  },
  data() {
    return {
        activeName: "0",
    };
  },
  mounted() {},
  methods: {
      handleClick (tab, event) {
        console.log(tab, event);
      }
  },
};
</script>

<style lang="less" scoped>
/deep/.el-tabs__item.is-active {
    color: #1AB394!important;
}
/deep/.el-tabs__item:hover{
    color: #1AB394!important;
}
/deep/.el-tabs__active-bar{
    background-color:  #1AB394!important;
}
</style>
