<template>
  <div class="orderlistmain">
    <div v-for="(item, index) in lecturelist" :key="index" class="orderlistdiv">
      <div class="top">
        <div class="state state1" v-if="item.state  == 0">未开始</div>
        <div class="state state2" v-if="item.state  == -1">已结束</div>
        <div class="state state3 " v-if="item.signInState   == 2">已签到</div>
      </div>
      <div class="content">
        <div class="contensmalldiv">
          <div class="smalltitle">讲座名称：</div>
          <div class="text text1">{{item.lectureTitle}}</div>
        </div>
        <div class="contensmalldiv">
          <div class="smalltitle">讲座地址：</div>
          <div class="text">{{item.lectureAddress}}</div>
        </div>
        <div class="contensmalldiv">
          <div class="smalltitle">讲座时间：</div>
          <div class="text">{{item.lectureTime}}</div>
        </div>
      </div>
    </div>
    <div class="pagination">
      <el-pagination
        background
        :hide-on-single-page="total <= 10"
        layout="prev, pager, next"
        :total="total"
        @current-change="handleChange"
        :current-page.sync="formline.current"
        :page-size="formline.size"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import {getSignUpLectureInfoPage} from "@/api/personalCenter.js";
export default {
  props: ['state'],
  data() {
    return {
      total: 0,
      lecturelist: [], //讲座列表
      formline: { size: 10, current: 1,state: null},
    };
  },
  watch:{
    state:{
      immediate: true,
      handler(a,b){     //a是value的新值，b是旧值
        this.formline.state = a
        this.getSignUpLectureInfoPage()
      },
      deep: true,
    }
  },
  methods: {
    // 跳页
    handleChange(page) {
      this.formline.current = page;
      this.getSignUpLectureInfoPage()
    },
    //获取列表
    getSignUpLectureInfoPage(){
      getSignUpLectureInfoPage(this.formline).then(res=>{
        if(res.code == 1){
          let data = res.data || {}
          this.total = data.total || 0
          this.lecturelist = data.records || []
        }
      })
    }
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #1ab394;
}
/deep/.el-pager li:hover {
    color: #1ab394!important;
}
.orderlistmain {
  width: 950px;
  .orderlistdiv {
    box-sizing: border-box;
    padding: 10px;
    width: 100%;
    height: 205px;
    border: 1px solid #f1f1f1;
    margin-bottom: 10px;
    .top {
      width: 100%;
      height: 62px;
      border-bottom: 1px solid #f1f1f1;
      .state {
        display: inline-block;
        width: 70px;
        height: 32px;
        line-height: 32px;
        color: #fff;
        text-align: center;
        margin-top: 10px;
        margin-right: 10px;
      }
      .state1{
        background: #f3bb5b;
      }
      .state2{
        background: #1AB394;
      }
      .state3{
        background: #CCCCCC;
      }
    }
    .content {
      margin-top: 10px;
      .contensmalldiv {
        height: 34px;
        display: flex;
        justify-content: flex-start;
        .smalltitle {
          width: 70px;
          height: 34px;
          line-height: 34px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
        }
        .text {
          width: 858px;
          height: 34px;
          line-height: 34px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
        }
        .text1{
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .pagination {
    margin-top: 30px;
  }
}
</style>
